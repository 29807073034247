import * as React from 'react'
import './styles.scss'

const Assurance = ({ backgroundColour }) => {
    return (
        <div
            className={`c-assurance c-assurance--${
                backgroundColour === 'gradient' || backgroundColour === 'dark'
                    ? 'light'
                    : 'dark'
            }`}
        >
            Safe | Secure | Privacy Protected
        </div>
    )
}

export default Assurance
